.top-cont {
  margin: 0;
  padding: 0;
  background: black;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diamond-container {
  position: relative;
  width: 0;
  height: 0;
}

.square {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 2px solid white;
  background: transparent;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  opacity: 1;
}

.square.top {
  cursor: grab;
  z-index: 2;
}

.square.top.active {
  border-color: red;
  cursor: grabbing;
}

.square.trail {
  animation: floatTrail 4.5s ease-out forwards;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  mix-blend-mode: screen;
  filter: blur(0.5px);
}

.square.trail.white {
  border-color: white;
}

.square.trail.red {
  border-color: red;
  box-shadow: 0 0 8px red;
}

@keyframes floatTrail {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    transform: scale(1.3);
    opacity: 0.2;
  }
}
