.top-container {
  margin: 0;
  padding: 0;
  background: black;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.triangle-grid {
  position: relative;
  width: 100vw;
  height: 100vh;
  cursor: crosshair;
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 21px solid white;
  transition: all 0.3s ease;
  transform-origin: center;
}

.triangle.ripple {
  animation: ripple 2.2s ease-in-out infinite;
}

@keyframes ripple {
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.85; }
}

.triangle.active {
  border-bottom-color: red;
}

.triangle.rotate-down {
  transform: rotate(180deg);
}

.triangle.rotated-red {
  border-bottom-color: red;
  transform: rotate(180deg);
  opacity: 0.8;
}

.shape-cross {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shape-cross .bar-horizontal,
.shape-cross .bar-vertical {
  position: absolute;
  background-color: red;
  border-radius: 1px;
}

.shape-cross .bar-horizontal {
  width: 14px;
  height: 2px;
}

.shape-cross .bar-vertical {
  width: 2px;
  height: 14px;
}

