
.form-style {
  width: 80vw;
  /* margin: auto; */
  /* padding: 2rem; */
}


.neg {
  color: tomato;
}

.pos {
  color: lightgreen;
}