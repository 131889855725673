nav a {
  color: white;
  transition: all 300ms;
}

nav a.active {
  font-weight: bold;
  color: red;

}

nav a:hover,nav a.active:hover {
  color: gray !important;
  text-decoration: none;
}

#nav-style {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  z-index: 555;
}