.logo-container {
  /* height: 100vh; */
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  padding: 20px;
  overflow: hidden;
  line-height: 1;
  /* background-color: black; */
  color: white;
  position: absolute;
  top: 20vh;
  left: 0;
}


.logo-container img {
width: 100vw;
height: auto;
}

.header {
  font-size: 200px;
  text-transform: uppercase;
  font-weight: 600;
 }

 .sub-header {
  /* width: 100%; */
  margin-top: 20px;

 }

 .project-links-section {
  width: fit-content;
  margin-top:20px;
 }

 .project-link {
  color: white !important;
  padding: 5px;
 }

 .project-link:hover {
  color: red !important;
 }

 .reminder {
  margin-top:10px;
  color: lightgray;
  font-size: 10px;
 }


@media only screen and (max-width:1000px) {
  .header {
    font-size:80px;
  }
  .sub-header {
    font-size: 18px;
  }
}
@media only screen and (max-width:600px) {
  .header {
    font-size:72px;
  }

  .sub-header {
    font-size: 12px;
  }
}
@media only screen and (max-width:400px) {
  .header {
    font-size:60px;
  }

}
