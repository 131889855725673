.design-grid-container {
  margin: auto;
  margin-bottom: 10%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  transition: 500ms all;
}

.grid-item {
  /* width: 100%; */
  transition: 500ms all;
  overflow: hidden;
  height: 350px;
  aspect-ratio: 1/1;
  overflow:hidden;
}

.grid-item:hover {
  border: 1px solid white;
  cursor: pointer;
  filter:brightness(70%);
}

.grid-item:active {
  border: 10px solid white;
}

/* modal styling for design grid item */

.design-modal {
  width: fit-content;
  position:fixed;
  cursor:pointer;
  z-index: 33;
  left: 0;
  top: 10%;
  right:0;
  margin-left:auto;
  margin-right: auto;
  transition: 300ms all;
  display: grid;
  border-radius: 0;
}


.modal-detail{
  background:white;
  color:black;
  padding: 10px;
  transition: 300ms all;


}



.modal-image {
  max-width: 500px;
  height: auto;
  margin:auto;
}


.hidden {
  display: none;
  transition: 300ms all;
}


/* Media queries for grid */

@media screen and (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;  
  }
  .modal-image {
    max-width: 300px;
    height: auto;
    margin:auto;
  }

}
@media screen and (max-width: 1000px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
  .modal-detail {
    opacity:.85;
    top:100%;
    padding: 5px;
    width: fit-content;
    height: auto;
  }

  .modal-detail p {
    font-size: small;
  }
}
@media screen and (max-width: 800px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .grid-item {
    height:max-content;
  }
}