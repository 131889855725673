.top-cont {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.line-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.h-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #242424;
  opacity: 1;
}

.red-ball {
  position: absolute;
  width: 200px;
  height: 200px;
  background: black;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  pointer-events: auto;
  border: 1px solid white;
}

.red-ball:hover {
  background-color: white;
  opacity: .6;
  border: 1px solid black;
}

.plus-sign {
  position: absolute;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  z-index: 6;
}

.plus-sign::before,
.plus-sign::after {
  content: '';
  position: absolute;
  background-color: white;
}

.plus-sign.red::before,
.plus-sign.red::after {
  background-color: red;
}

.plus-sign::before {
  width: 12px;
  height: 2px;
  top: 5px;
  left: 0;
}

.plus-sign::after {
  width: 2px;
  height: 12px;
  top: 0;
  left: 5px;
}
