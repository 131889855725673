.top-cont {
  margin: 0;
  padding: 0;
  background: black;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.boop-rain-line {
  position: absolute;
  width: 2px;
  background-color: white;
  transition: background-color 0.5s ease;
  pointer-events: none;
}

.rain-line.faded {
  background-color: grey;
}

.rain-line.red {
  background-color: red;
}
