.top-cont {
  margin: 0;
  padding: 0;
  background: black;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.ascii-grid {
  position: absolute;
  top: 0;
  left: 0; /* 🧠 no more 20vw here — JS now handles alignment */
}

.plus {
  position: absolute;
  font-family: monospace;
  font-size: 16px;
  text-align: center;
  user-select: none;
  transition: transform 0.2s ease-out;
}

.plus.faint     { color: #444; }
.plus.dim       { color: #666; }
.plus.shadow    { color: #888; }
.plus.bright    { color: #ccc; }
.plus.white     { color: white; }
.plus.highlight { color: #eee; }
.plus.bold      { color: #fefefe; }
.plus.red       { color: red; }
