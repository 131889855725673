
a {
  color:white;
  transition: all 300ms;
}

a:hover {
  color: #f1f1f1 !important;
}

.app {
  min-height: 100vh;
  /* text-align: center; */
  font-family: 'Poppins', sans-serif;
  background-color: black;
  color: white;
}

body {
    background-color:black;
}


h2 {
  font-size: 42px;
  font-family: 'Poppins', sans-serif;
  color: white;

}

h3 {
  font-size: 36px;
  font-family: 'Poppins', sans-serif;
  color:white;
}

h4,h5,h6 {
  font-family: 'Poppins', sans-serif;

}

a i {
  transition: all 300ms;
}

a i:hover{
  color:#0096c3 !important;
}

.line {
  border: 1px solid white;
  width: 70%;
}

p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;

}

/* COMMON CLASSES */

.page-header {
  font-size: 80px;
  line-height: 1;
}

.close-btn {
  border: 2px solid red;
  background-color:#f1f1f1;
  top: 8px;
  right: 8px;
  padding: 2px;
  color: red;
  z-index: 44;
  position:absolute;
  transition: 300ms all;
  cursor: pointer;
}


.close-btn:hover {
  border: 2px solid #f1f1f1;
  color: #f1f1f1;
  background-color: red; 
}

.night-pallete{
  background-color: black;
  color: white;
}

/* FONTS */

.aguafina-font {
  font-family: 'Aguafina Script', cursive;
}

.alata-font {
  font-family: 'Alata', sans-serif;
}

.poppins-font {
  font-family: 'Poppins', sans-serif;
}

.prompt-font {
  font-family: 'Prompt', sans-serif;
}

.righteous-font {
  font-family: 'Righteous', cursive;
}

/* MAIN COLORS */

.bg-color {
  background-color: black;
}

.off-black-bg-color {
  background-color: #242424;
}

.bg-teal {
  background-color: #2b768d;
}

.light-blue-color {
  background-color: #2b768d;
}

.light-blue-text {
  color: rgb(29, 183, 235);
}

.teal-text {
  color: #2b768d;
}

.grey-font {
  color: #BFBFBF;
}



